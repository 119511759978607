<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-5 xl-100 box-col-12">
          <px-card class="knob-widgets" style="width: 80%">
            <div style="display: flex">
              <div>
                <apexchart height="200" type="radialBar" :options="apex_chart.options" :series="apex_chart.series"></apexchart>
              </div>
              <div style="margin-left: -7%;">
              <div class="mt-4">
                <h5 class="f-w-600">
                  Debt
                </h5>
              </div>
                <div class="knob-bottom-widgets text-center">
                  <h6 class="f-w-600">Outstanding Loan Balance</h6>
                </div>
                <h5 class="f-w-600">
                  200k
                </h5>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-xl-3 xl-50 col-md-6 box-col-6">
          <px-card class="tag-card">
            <div slot="with-padding" class="progressbar-widgets">
              <div class="media media-widgets">
                <div class="media-body">
                  <h3 class="mt-0 mb-0 f-w-600">
                    <feather type="dollar-sign"></feather>
                    <span class="counter">841,162</span>
                  </h3>
                  <p class="mb-0">Eligible Loan Amount</p>
                </div>
              </div>
              <span class="tag-content-secondary tag-hover-effect">
                    <feather type="trending-up"></feather>
                  </span>
            </div>
          </px-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                 <h5>Loan History</h5>
                </div>
                <div class="col-md-5">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>

                <div class="col-md-4">
                  <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                  >
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-1">
                  <button class="btn btn-pill btn-gradient-custom" @click="$bvModal.show('bv-modal-example')">Pay</button>
                </div>

                <b-modal id="bv-modal-example" centered hide-footer>
                  <form @submit.prevent="handlePay">

                    <div class="form-group">
                      <label>Amount</label>
                      <input type="number" step="0.001" class="form-control" v-model="form.amount" required>
                    </div>

                    <div class="form-group">
                      <label>Mode of payment</label>
                      <select v-model="form.mode_of_payment"  class="form-control" required>
                        <option value="Bank">Bank</option>
                        <option value="Phone">Phone</option>
                        <option value="Phone">Till</option>
                      </select>
                    </div>

                    <div class="form-group text-center">
                      <button class="btn btn-pill btn-gradient-custom" type="submit">Pay Advance</button>
                    </div>
                  </form>
                </b-modal>

              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="advances.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="advances.data && advances.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {advance_fields} from "@/pages/advances/fields/advance_field_defs";
import {auth_user} from '@/helpers/general';
export default {
  data() {
    return {
      fields: advance_fields,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      searchTitle: "",
      authuser:auth_user,
      form:{
        amount:'',
        mode_of_payment:''
      },
      apex_chart: {
        options: {
          chart: {
            height: 350,
            type: 'radialBar'
          },
          labels: [''],
          colors: ['#005677'],
          stroke: {
            lineCap: "round"
          },

          plotOptions: {
            radialBar: {
              hollow: {
                size: '70%',
              },
              dataLabels: {
                name: {
                  fontSize: '28px',
                },
                value: {
                  fontSize: '20px',
                },
              },
            }
          },
        },
        series: [65]
      },
    }
  },
  mounted() {
    this.retrieveAdvances();
  },
  computed:{
    ...mapGetters({advances:'getAdvances'})
  },
  watch: {
    advances() {
      this.totalRows = this.advances.total;
    },
    searchTitle() {
      this.retrieveAdvances();
    }
  },
  methods:{
    handlePay() {
    },
    retrieveAdvances() {
      let params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      const item = JSON.parse(localStorage.getItem('user'));
      params.user_id = item.user.id;
      this.$store.dispatch('allAdvances',params)
    },
    handlePageChange(value) {
       this.currentPage = value
       this.retrieveAdvances();
    }
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}
.tag-card .tag-hover-effect.tag-content-secondary {
  background: #005677;
}
</style>
