import axios from 'axios';
class AuthService {
    login(user) {
        return axios
            .post('auth/login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    localStorage.setItem('merchant_id',response.data.merchant_id);
                    localStorage.setItem('user_id',response.data.user_id);
                    localStorage.setItem('is_finance',response.data.is_finance);
                    localStorage.setItem('timestamp_session',response.data.timestamp_session);
                    localStorage.setItem('token',response.data.accessToken);
                }
                return response.data;
            });
    }
    logout() {
       localStorage.clear();
    }
    register(user) {
        return axios.post( 'auth/register', {
            first_name: user.first_name,
            last_name: user.first_name,
            phone: user.phone,
            email: user.email,
            password: user.password,
            password_confirmation: user.password_confirmation,
        });
    }

    sendEmail(email) {
       return axios.post('password/email',{email})
    }

    reset(user) {
        return axios.post(`password/reset/token=${user.token}`,user)
    }
}
export default new AuthService();
