import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body'

/* sample page */
import Dashboard from '../pages/dashboard/index'
import AvailableAmount from '../pages/payments/available_amount'
import PaymentRequest from '../pages/payments/payment_request'
import Payout from '../pages/payments/pay_out'
import ValidatedOrders from '../pages/orders/validated_orders'
import UnvalidatedOrders from '../pages/orders/unvalidated_orders'
import Refunds from '../pages/orders/refunds'
import Advance from '../pages/advances/index'
import History from '../pages/advances/history'
import NewAdvance from '../pages/advances/add'
import Users from '../pages/users/index'
import AddUser from '../pages/users/add'
import Roles from '../pages/users/roles/index'
import SamplePage from '../pages/sample_page'
import Auth from '../pages/auth/auth'
import login from '../pages/auth/login'
import forgotPassword from '../pages/auth/forgotPassword'
import register from  '../pages/auth/Register'
import resetPassword from  '../pages/auth/confirmPassword'
import CustomerGoal from "@/pages/goals/CustomerGoal";
import moment from "moment/moment";

// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'index' }},

  {
    path: '/dashboard',
    component: Body,
    children: [
    {
      path: '/',
      name: 'index',
      component: Dashboard,
      meta: {
        title: 'Default Dashboard | Endless - Premium Admin Template',
      }
    },
    {
      path: 'ecommerce',
      name: 'ecommerce',
      component: SamplePage,
      meta: {
        title: 'Ecommerce Dashboard | Endless - Premium Admin Template',
      }
    }
    ]
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: login,
        meta: {
          title: 'Login | Flexpay - Dashboard',
        }
      },
      {
        path: 'register',
        name: 'Register',
        component: register,
        meta: {
          title: 'Register | Flexpay - Dashboard',
        }
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: forgotPassword,
        meta: {
          title: 'Forgot Password | Flexpay - Dashboard',
        }
      },
      {
        path: 'reset-password',
        name: 'Reset Password',
        component: resetPassword,
        meta: {
          title: 'Reset Password | Flexpay - Dashboard',
        }
      }
    ]
  },

  {
    path: '/payments',
    component: Body,
    children: [
      {
        path: 'available-amount',
        name: 'available-amount',
        component: AvailableAmount,
        meta: {
          title: 'Available Amount | Flexpay - Dashboard',
        }
      },
      {
        path: 'pay-out',
        name: 'pay-out',
        component: Payout,
        meta: {
          title: 'Payouts | Flexpay - Dashboard',
        },
      },
      {
        path: 'requests',
        name: 'requests',
        component: PaymentRequest,
        meta: {
          title: 'Payment Requests | Flexpay - Dashboard',
        },
      },
    ]
  },
  {
    path: '/orders',
    component: Body,
    children: [
      {
        path: 'validated',
        name: 'validated',
        component: ValidatedOrders,
        meta: {
          title: 'validated Orders | Flexpay - Dashboard',
        }
      },
      {
        path: 'unvalidated',
        name: 'unvalidated',
        component: UnvalidatedOrders,
        meta: {
          title: 'Unvalidated Orders | Flexpay - Dashboard',
        }
      },
      {
        path: 'refund',
        name: 'refund',
        component: Refunds,
        meta: {
          title: 'Refunds | Flexpay - Dashboard',
        }
      },
    ]
  },
  {
    path: '/advance',
    component: Body,
    children: [
      {
        path: 'all',
        name: 'all',
        component: Advance,
        meta: {
          title: 'Advances | Merchant - Dashboard',
        }
      },
      {
        path: 'history',
        name: 'history',
        component: History,
        meta: {
          title: 'Advances History | Merchant - Dashboard',
        }
      },
      {
        path: 'request',
        name: 'request',
        component: NewAdvance,
        meta: {
          title: 'Request Advance | Merchant - Dashboard',
        }
      },
    ]
  },
  {
    path: '/goals',
    component: Body,
    children: [
      {
        path: '',
        name: 'customer',
        component: CustomerGoal,
        meta: {
          title: 'Customer Goal | Merchant - Dashboard',
        }
      }
    ]
  },
  {
    path: '/users',
    component: Body,
    children: [
      {
        path: 'all',
        name: 'all-users',
        component: Users,
        meta: {
          title: 'Users | Merchant - Dashboard',
        }
      },
      {
        path: 'new',
        name: 'new',
        component: AddUser,
        meta: {
          title: 'New User | Merchant - Dashboard',
        }
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
        meta: {
          title: 'User Roles | Merchant - Dashboard',
        }
      },
    ]
  },
  ];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/login', '/auth/register','/auth/forgot-password','/auth/reset-password'];
  var authRequired = !publicPages.includes(to.path);


  var loggedIn = false;
  var token = localStorage.getItem('token');
  var logout_timestamp = localStorage.getItem('timestamp_session');

  if (logout_timestamp && token) {
    var timestamp_now = moment().unix();
    if (logout_timestamp > timestamp_now) {
      loggedIn = true;
    }
  }
  // trying to access a restricted page + not logged in
  // redirect to login page

  if (authRequired && !loggedIn) {
    localStorage.clear();
    return next({
      path: '/auth/login'
    });
  } else {
    next();
  }
});



// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });
export default router
