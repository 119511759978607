<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card title="Add User">
            <div slot="with-padding">
                <div class="text-center">
                  <div v-for="(errorArray, idx) in errors" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors}} </span>
                    </div>
                  </div>
                </div>
              <b-form class="needs-validation" @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-8 offset-md-2  mb-3">
                    <label for="c_form_first_name">First name</label>
                   <b-form-input type="text" id="c_form_first_name" v-model="form.first_name" placeholder="First Name"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.first_name">Please provide your first name.</b-form-invalid-feedback>
                 </div>
                </div>

                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_last_name">Last Name</label>
                    <b-form-input type="text" id="c_form_last_name" v-model="form.last_name" placeholder="Last Name"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.last_name">Please provide your last name.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_email">Email</label>
                    <b-form-input type="email" id="c_email" v-model="form.email" :state="form_result.account_name" placeholder="Email"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.email">Please provide a valid Email.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_phone">Phone</label>
                    <b-form-input type="text" id="c_phone" v-model="form.phone_number" :state="form_result.phone_number" placeholder="Phone Number"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.phone_number">Please provide a phone number.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_password">Password</label>
                    <b-form-input type="password" id="c_password" v-model="form.password" :state="form_result.password" placeholder="Password"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.password">Please provide a Password.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_password_confirmation"> Confirm Password</label>
                    <b-form-input type="password" id="c_password_confirmation" v-model="form.password_confirmation" :state="form_result.password_confirmation" placeholder="Password Confirmation"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.password_confirmation">Please confirm Password.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_role">Role</label>
                    <b-form-select v-model="form.role_id" :options="roles" :state="form_result.role_id"></b-form-select>
                    <b-form-invalid-feedback :state="form_result.role_id">Please provide a role.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_role">Outlet</label>
                    <b-form-select v-model="form.outlet_id" :options="outlets" :state="form_result.outlet_id"></b-form-select>
                    <b-form-invalid-feedback :state="form_result.outlet_id">Please provide an outlet.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="text-center">
                 <b-button type="submit" class="btn btn-pill btn-gradient-custom">Submit Request</b-button>
                </div>
              </b-form>
            </div>
          </px-card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapGetters, mapState} from "vuex";
export default {

  components:{
    Datepicker
  },
  data(){
    return {
      errors:[],
      form:{
        first_name:'',
        last_name:'',
        email:'',
        phone_number:'',
        outlet_id:'',
        password:'',
        password_confirmation:'',
        role_id:'',
      },
      form_result:{
        first_name:null,
        last_name:null,
        outlet_id:null,
        email:null,
        phone_number:null,
        password:null,
        password_confirmation:null,
        role_id:null,
      },
    }
  },
  mounted() {
    let item = JSON.parse(localStorage.getItem('user'));
    this.form.user_id = item.user.id;
    this.$store.dispatch('updateRoles');
    this.$store.dispatch('updateOutlet',this.form.user_id);
  },

  computed:{
    ...mapGetters({roles:'getRoles',outlets:'getOutlets'})
  },
  methods:{
    onSubmit(evt){
      evt.preventDefault()
      if(this.form.first_name != '')
      {
        this.form_result.first_name = true
      }
      else
      {
        this.form_result.first_name = false
      }

      if(this.form.last_name != '')
      {
        this.form_result.last_name = true
      }
      else
      {
        this.form_result.last_name = false
      }

      if(this.form.email != '')
      {
        this.form_result.email = true
      }
      else
      {
        this.form_result.email = false
      }

      if(this.form.phone_number != '')
      {
        this.form_result.phone_number = true
      }
      else
      {
        this.form_result.phone_number = false
      }

      if(this.form.password != '')
      {
        this.form_result.password = true
      }
      else
      {
        this.form_result.password = false
      }

      if(this.form.password_confirmation != '')
      {
        this.form_result.password_confirmation = true
      }
      else
      {
        this.form_result.password_confirmation = false
      }

      if(this.form.role_id != '')
      {
        this.form_result.role_id = true
      }
      else
      {
        this.form_result.role_id = false
      }
      if(this.form.outlet_id != '')
      {
        this.form_result.outlet_id = true
      }
      else
      {
        this.form_result.outlet_id = false
      }

      this.$store.dispatch('addStaff',this.form).then(() =>{
        this.$toasted.show('User Successfully added.', {theme: 'outline',position: "top-right", fullWidth:true, type: 'success', duration: 2000});
        this.$router.push({path:'/users/all'})
      }).catch(err => {
        console.log('error case')
        console.log(err.response)
        console.log(err.response.data)
        this.errors = err.response.data

      })
    },

  }
}
</script>

<style scoped>

</style>
