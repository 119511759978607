export function getRequestParams(search, page, pageSize) {
    let params = {};
   // if (search) {
        params["search"] = search;
    //}
    if (page) {
        params["page"] = page;
    }
    if (pageSize) {
        params["size"] = pageSize;
    }
    return params;
}
