<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-9">
          <div class="row">
            <!-- Card-3 : Bitcoin -->
            <div class="col-xl-6 xl-50">
              <px-card class="crypto-graph-card sales-overview" :actions="false">
                <div slot="headerCustom">
                  <div class="media">
                    <div class="media-body d-flex">
                      <div class="rounded-icon bck-gradient-primary"><i class="fa fa-money"></i></div>
                      <div class="bitcoin-graph-content">
                        <h5>Available Balance</h5><span class="d-block f-12 f-w-600">KES {{ stats.balance }}</span>
                      </div>
                    </div><span class="pull-right badge badge-pill">
                        <span class="font-primary mt-0"><span class="d-inline-block mt-0"><i class="fa fa-sort-up font-primary"></i></span> 7.8%</span></span>
                  </div>
                </div>
              </px-card>
            </div>
            <!-- Card-4 : Litecoin -->
            <div class="col-xl-6 xl-50">
              <px-card class="crypto-graph-card sales-overview secondary-crypto" :actions="false">
                <div slot="headerCustom">
                  <div class="media">
                    <div class="media-body d-flex">
                      <div class="rounded-icon bck-gradient-secondary"><i class="fa fa-money"></i></div>
                      <div class="bitcoin-graph-content">
                        <h5>Unvalidated Amount</h5><span class="d-block f-12 f-w-600">KES {{stats.unvalidated_amount }}</span>
                      </div>
                    </div>
                    <div class="right-setting">
                        <div class="setting-dot d-inline-block mr-3">
                        <div class="setting-bg"><i class="fa fa-spin fa-cog font-secondary"></i></div>
                      </div><span class="pull-right badge badge-pill">
                          <span class="font-secondary mt-0"><span class="d-inline-block mt-0"><i class="fa fa-sort-up font-secondary"></i></span> 4.1%</span></span>
                    </div>
                  </div>
                </div>
              </px-card>
            </div>
          </div>
        </div>
      </div>
          </div>
    <!-- Container-fluid Ends -->
  </div>
</template>
<script>
import * as Chartist from 'chartist';
import TotalSales from "@/pages/charts/chartjs/TotalSales";
import {mapGetters} from "vuex";
export default {
  components:{
    'Linechart':TotalSales
  },
  data() {
    return {
      cardOption: false,
      marketData: [
        {
          coin: "Bitcoin (BTC)",
          price: "$ 50.65",
          date: "19 Jan",
          change: "+2.6%",
          className: "pill-badge-primary"
        },
        {
          coin: "Ethereum (ETH)",
          price: "$ 9.65",
          date: "14 Feb",
          change: "-3.7%",
          className: "pill-badge-secondary"
        },
        {
          coin: "Ripple (XRP)",
          price: "$ 30.48",
          date: "21 March",
          change: "+2.6%",
          className: "pill-badge-primary"
        },
        {
          coin: "Bitcoin Case (BCH)",
          price: "$ 102.56",
          date: "1 April",
          change: "+5.9%",
          className: "pill-badge-light font-primary"
        },
        {
          coin: "EOS (EOS)",
          price: "$ 5.48",
          date: "8 May",
          change: "-2.3%",
          className: "pill-badge-primary"
        },
        {
          coin: "Litecoin (LTC)",
          price: "$ 40.78",
          date: "20 Sep",
          change: "+4.3%",
          className: "pill-badge-secondary"
        }
      ],
      sellOrders: [
        {
          price: "0.98422432",
          eth: "0.0953744",
          btc: "8.64923753",
          usd: "0.00718167"
        },
        {
          price: "0.88422432",
          eth: "0.0253744",
          btc: "4.64923753",
          usd: "0.00711167"
        },
        {
          price: "0.18422432",
          eth: "0.0153744",
          btc: "5.64923753",
          usd: "0.11718167"
        },
        {
          price: "0.98922932",
          eth: "0.9953744",
          btc: "9.64923753",
          usd: "0.90718167"
        },
        {
          price: "0.28429439",
          eth: "0.5953744",
          btc: "6.04123753",
          usd: "0.59718167"
        },
        {
          price: "0.48422432",
          eth: "0.0353144",
          btc: "3.64553753",
          usd: "0.30718167"
        },
        {
          price: "0.78922432",
          eth: "0.6953744",
          btc: "5.64989753",
          usd: "0.20718167"
        },
        {
          price: "0.91732032",
          eth: "0.7050744",
          btc: "7.60123115",
          usd: "0.85718526"
        }
      ],
      buyOrders: [
        {
          price: "0.98422432",
          eth: "5.09537441",
          btc: "8.64923753",
          usd: "0.00718167"
        },
        {
          price: "0.88422432",
          eth: "4.64923753",
          btc: "4.64923753",
          usd: "0.00711167"
        },
        {
          price: "0.18422432",
          eth: "2.01537444",
          btc: "5.64923753",
          usd: "0.11718167"
        },
        {
          price: "0.98922932",
          eth: "7.99537441",
          btc: "9.64923753",
          usd: "0.90718167"
        },
        {
          price: "0.48422432",
          eth: "3.03531449",
          btc: "6.64553753",
          usd: "0.30718167"
        },
        {
          price: "0.78922432",
          eth: "4.69537441",
          btc: "5.64989753",
          usd: "0.20718167"
        },
        {
          price: "0.28429439",
          eth: "9.59537441",
          btc: "6.04123753",
          usd: "0.59718167"
        },
        {
          price: "0.91732032",
          eth: "4.70507448",
          btc: "7.60123115",
          usd: "0.85718526"
        }
      ],
      chart: {
        chartData_1: {
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
          series: [
            [10, 9.5, 7, 6.8, 6.8, 5, 4, 4.2, 3, 3.8, 3.7, 2.5, 2, 1, 0, null, null, null, null, null, null, null, null, null, null, null, null, null,],
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0, 1, 2, 2.5, 2.4,  3.5,  5,  6, 6.2, 6.1, 6.8, 7.2, 8, 8.8, 9.5],
          ]
        },
        chartOptions: {
          fullWidth: true,
          showArea: true,
          height: 445,
          chartPadding: {
            left: 20,
            bottom: 7,
            right: 7,
            top: 15
          },
          low: 0,
          axisX: {
            showGrid: false,
            showLabel: false,
            offset: 0
          }
        },
        eventHandlers: [
          {
            event: "draw",
            fn(data) {
              if(data.type === 'point') {
                var circle = new Chartist.Svg('circle', {
                  cx: [data.x], cy:[data.y], r:[5],
                }, 'ct-circle');
                data.element.replace(circle);
              }
            }
          }
        ]
      },
      apexBitcoin: {
        options: {
          chart: {
            width: 510,
            height: 205,
            type: "area",
            toolbar: {
              show: false
            }
          },
          colors: ["#158df7"],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          xaxis: {
            show: false,
            type: "datetime",
            categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00", "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00", "2018-09-19T06:30:00", "2018-09-19T07:30:00", "2018-09-19T08:30:00", "2018-09-19T09:30:00"],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            show: false
          },
          fill: {
            colors: ["#158df7"],
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 90, 100]
            }
          },
          markers: {
            size: 6,
            strokeWidth: 3,
            strokeColor: '#158df7',
            colors: ['#ffffff'],
            hover: {
                size: 7,
                sizeOffset: 3
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm"
            }
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0
            }
          }
        },
        series: [
          {
            name: "series1",
            data: [15, 15, 16, 15, 16, 16, 17, 17, 17, 19]
          }
        ]
      },
      apexLitecoin: {
        options: {
          chart: {
            width: 510,
            height: 205,
            type: "area",
            toolbar: {
              show: false
            }
          },
          colors: ["#fb2e63"],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          xaxis: {
            show: false,
            type: "datetime",
            categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00", "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00", "2018-09-19T06:30:00", "2018-09-19T07:30:00", "2018-09-19T08:30:00"],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            show: false
          },
          fill: {
            colors: ["#fb2e63"],
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 90, 100]
            }
          },
          markers: {
            size: 6,
            strokeWidth: 3,
            strokeColor: '#fb2e63',
            colors: ['#ffffff'],
            hover: {
                size: 7,
                sizeOffset: 3
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm"
            }
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0
            }
          }
        },
        series: [
          {
            name: "series1",
            data: [15, 15, 17, 15, 19, 18, 18, 18, 16]
          }
        ]
      },
      apexEthereum: {
        options: {
          chart: {
            width: 510,
            height: 205,
            type: "area",
            toolbar: {
              show: false
            }
          },
          colors: ["#fb740d"],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          xaxis: {
            show: false,
            type: "datetime",
            categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00", "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00", "2018-09-19T06:30:00", "2018-09-19T07:30:00"],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            show: false
          },
          fill: {
            colors: ["#fb740d"],
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 90, 100]
            }
          },
          markers: {
            size: 6,
            strokeWidth: 3,
            strokeColor: '#fb740d',
            colors: ['#ffffff'],
            hover: {
                size: 7,
                sizeOffset: 3
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm"
            }
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0
            }
          }
        },
        series: [
          {
            name: "series1",
            data: [15, 15, 17, 15, 19, 18, 18, 16]
          }
        ]
      },
      apexCandleChart: {
        options: {
          chart: {
            width: 854,
            height: 385,
            type: "candlestick",
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            candlestick: {
                colors: {
                    upward: "#158df7",
                    downward: "#fb2e63"
                }
            }
          },
          colors: ["#fb740d"],
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'datetime',
            show: false,
            labels: {
                low: 0,
                offsetX: 0,
                show: false,
            },
            axisBorder: {
                low: 0,
                offsetX: 0,
                show: false,
            },
            axisTicks: {
                show: false,
            },
          },
          yaxis: {
              tooltip: {
                  enabled: true
              }
          },
          fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.2,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
          },
          grid: {
              borderColor: '#f6f9fd',
          }
        },
        series: [{
          data: [{
                  x: new Date(1538789400000),
                  y: [6624.61, 6632.2, 6617, 6626.02]
              },
              {
                  x: new Date(1538791200000),
                  y: [6627, 6627.62, 6584.22, 6603.02]
              },
              {
                  x: new Date(1538793000000),
                  y: [6605, 6608.03, 6598.95, 6604.01]
              },
              {
                  x: new Date(1538794800000),
                  y: [6604.5, 6614.4, 6602.26, 6608.02]
              },
              {
                  x: new Date(1538796600000),
                  y: [6608.02, 6610.68, 6601.99, 6608.91]
              },
              {
                  x: new Date(1538798400000),
                  y: [6608.91, 6618.99, 6608.01, 6612]
              },
              {
                  x: new Date(1538800200000),
                  y: [6612, 6615.13, 6605.09, 6612]
              },
              {
                  x: new Date(1538802000000),
                  y: [6612, 6624.12, 6608.43, 6622.95]
              },
              {
                  x: new Date(1538803800000),
                  y: [6623.91, 6623.91, 6615, 6615.67]
              },
              {
                  x: new Date(1538805600000),
                  y: [6618.69, 6618.74, 6610, 6610.4]
              },
              {
                  x: new Date(1538807400000),
                  y: [6611, 6622.78, 6610.4, 6614.9]
              },
              {
                  x: new Date(1538809200000),
                  y: [6614.9, 6626.2, 6613.33, 6623.45]
              },
              {
                  x: new Date(1538811000000),
                  y: [6623.48, 6627, 6618.38, 6620.35]
              },
              {
                  x: new Date(1538812800000),
                  y: [6615, 6627.40, 6584.10, 6603.00]
              },
              {
                  x: new Date(1538814600000),
                  y: [6615.53, 6617.93, 6610, 6615.19]
              },
              {
                  x: new Date(1538816400000),
                  y: [6615.19, 6621.6, 6608.2, 6620]
              },
              {
                  x: new Date(1538818200000),
                  y: [6619.54, 6625.17, 6614.15, 6620]
              },
              {
                  x: new Date(1538820000000),
                  y: [6620.33, 6634.15, 6617.24, 6624.61]
              },
              {
                  x: new Date(1538821800000),
                  y: [6625.95, 6626, 6611.66, 6617.58]
              },
          ]
        }],
      }
    };
  },
  created() {
  this.$store.dispatch('updateStats');
  //this.$store.dispatch('updateTopGoal');
  },
  watch:{
    goals() {
     // console.log('opp___kkk+++')
     // console.log(this.goals)
    }
  },
  computed:{
    ...mapGetters({stats:"getStats"})
  },
  methods: {
    openCardOption() {
      this.cardOption = !this.cardOption;
    }
  }
};
</script>

<style scoped>

</style>
