import orders from "@/services/Orders";

const state = {
    validated:[],
    unvalidated:[],
    refund:[],
    total_items:0
}

const getters = {
    getValidated: (state) => {
        return state.validated;
    },
    getUnvalidated: (state) => {
        return state.unvalidated;
    },
    getRefund: (state) => {
        return state.refund;
    },
}

const mutations = {
    updateValidated: (state,payload) =>{
        state.validated = payload;
        state.total_items = payload.total
    },
    updateUnvalidated: (state,payload) =>{
        state.unvalidated = payload;
        state.total_items = payload.total
    },
    updateRefund: (state,payload) =>{
        state.refund = payload;
        state.total_items = payload.total
    }
}

const actions = {
    updateValidated({commit},payload) {
        return orders.validated(payload).then(
            response => {
                commit('updateValidated',response);
               return Promise.resolve(response)
            },
            error => {
             return Promise.reject(error);
            }
        )
    },
    updateUnvalidated({commit},payload) {
        return orders.unvalidated(payload).then(
            response => {
                commit('updateUnvalidated',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    updateRefund({commit},payload) {
        return orders.refund(payload).then(
            response => {
                commit('updateRefund',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

