export const payment_request_field_defs = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'merchant',
        label: 'Merchant',
        sortable: true
    },
    {
        key: 'date_created',
        label: 'Date',
        sortable: true,
    },
    {
        key: 'amount',
        sortable: true,
    },
    {
        key: 'mode',
        sortable: true,
    },
    {
        key: 'account_name',
        sortable: true,
    },
    {
        key: 'actions',
        label: ''
    }
];
