import advance from "@/services/Advance";

const state = {
    loan_packages:{},
    active_loan_package:{},
    advances:{},
}

const getters = {
    getloanPackages(state) {
       return state.loan_packages;
    },
    activePackage(state){
        return state.active_loan_package;
    },
    getAdvances(state) {
       return state.advances;
    }
}

const mutations = {
    updateLoanPackages(state,payload){
       state.loan_packages = payload;
    },
    updateActivePackage(state,payload) {
        state.active_loan_package = payload;
    },
    updateAdvances(state,payload) {
        state.advances = payload;
    }
}

const actions = {
    updateLoanPackages({commit}) {
      return advance.getLoanPackages().then(response => {
          commit("updateLoanPackages",response);
          return Promise.resolve(response);
      },
       error => {
          return Promise.reject(error);
       }
      )
    },

    activatePackage({commit},payload) {
        return advance.activatePackage(payload).then(response => {
            commit('activatePackage',response.data)
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },

    activePackage({commit},payload) {
        return advance.getActivatedPackage(payload).then(response => {
                commit('updateActivePackage',response)
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },

    addAdvance({commit},payload) {
        return advance.addAdvance(payload).then(response => {
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },

    allAdvances({commit},payload) {
        return advance.getAdvances(payload).then(response => {
            commit('updateAdvances',response);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },

}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}
