import axios from 'axios';

class RoleService {
    getRoles() {
       return  axios.get('role')
            .then(res => {
                return res.data;
            })
    }

    getPermissions() {
        return  axios.get('permission')
            .then(res => {
                return res.data;
            })
    }

    addPermissions(payload) {
        return  axios.post('permission',payload)
            .then(res => {
                return res.data;
            })
    }
}


export default new RoleService();

