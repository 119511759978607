import axios from "axios";

const merchant_id = localStorage.getItem('merchant_id');
class OrderService {
    validated(payload){
        let url = `order/validated?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{merchant_id})
            .then((response) => {
                console.log('validated..')
                return response.data;
            })
    }

    unvalidated(payload){
        let url = `order/unvalidated?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        console.log('unvalidated')
        return axios.post(url,{merchant_id})
            .then((response) => {
                return response.data;
            })
    }

    refund(payload){
        let url = `order/refund?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{merchant_id})
            .then((response) => {
                return response.data;
            })
    }

    validateOrder(payload){
        return axios.post('order/validate-order',payload)
            .then((response) => {
                return response.data;
            })
    }

}

export default new OrderService();

