<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card title="Request Advance">
            <div slot="with-padding">
              <b-form class="needs-validation" @submit="onSubmit">
                <div class="form-row">

                  <div class="col-md-8 offset-md-2  mb-3">
                    <label for="c_form_amount">Amount</label>
                   <b-form-input type="text" id="c_form_amount" v-model="form.amount" placeholder="Amount" required></b-form-input>
                 </div>
                </div>

                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_payable_by">Payable By</label>
                    <datepicker input-class="datepicker-here form-control digits" id="c_payable_by" v-model="form.payable_by" :state="form_result.payable_by" :format="format" ></datepicker>
                    <b-form-invalid-feedback :state="form_result.payable_by">Please provide a valid Date.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_account_name">Account Name</label>
                    <b-form-input type="text" id="c_account_name" v-model="form.account_name" :state="form_result.account_name" placeholder="Account Name" disabled="disabled"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.account_name">Please provide a valid Account Name.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 offset-md-2 mb-3">
                    <label for="c_account_number">Account Number</label>
                    <b-form-input type="text" id="c_account_number" v-model="form.account_number" :state="form_result.account_number" placeholder="Account Number" disabled="disabled"></b-form-input>
                    <b-form-invalid-feedback :state="form_result.account_number">Please provide a valid Account Number.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="text-center">
                  <b-button type="submit" class="btn btn-pill btn-gradient-custom">Submit Request</b-button>
                </div>

              </b-form>
            </div>
          </px-card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapGetters, mapState} from "vuex";
export default {

  components:{
    Datepicker
  },
  data(){
    return {
      format: "MM/dd/yyyy",
      form:{
        amount:'',
        payable_by:'',
        account_name:'',
        account_number:'',
      },
      form_result:{
        payable_by:null,
        account_name:null,
        account_number:null,
      },
    }
  },

  watch:{
    activePackage() {
      this.form.amount = this.activePackage.loan_package.amount;
    },
    getStaff(){
      this.form.account_name = this.getStaff.first_name+' '+this.getStaff.last_name;
      this.form.account_number = this.getStaff.phone_number;
    }
  },
  mounted() {
    let item = JSON.parse(localStorage.getItem('user'));
    let user_id = item.user.id;
    this.$store.dispatch('activePackage',user_id);
    this.$store.dispatch('updateStaff',user_id);
  },

  computed:{
    ...mapGetters(['activePackage','getStaff'])
  },
  methods:{
    onSubmit(evt){
      evt.preventDefault()
      if(this.form.payable_by != '')
      {
        this.form_result.payable_by = true
      }
      else
      {
        this.form_result.payable_by = false
      }

      if(this.form.account_name != '')
      {
        this.form_result.account_name = true
      }
      else
      {
        this.form_result.account_name = false
      }

      if(this.form.account_number != '')
      {
        this.form_result.account_number = true
      }
      else
      {
        this.form_result.account_number = false
      }

      this.form.user_id = this.getStaff.user_id;

      this.$store.dispatch('addAdvance',this.form).then(() =>{
        this.$toasted.show('Advance Successfully submitted.', {theme: 'outline',position: "top-right", fullWidth:true, type: 'success', duration: 2000});
        this.$router.push({path:'/advance/history'})
      })
    },

  }
}
</script>

<style scoped>

</style>
