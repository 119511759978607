import axios from "axios";

class OutletStaffService {

    getStaff(payload){
       return axios.get(`outlet-staff/${payload}`)
           .then(res => {
               return res.data;
           })
    }

    addStaff(payload){
       return axios.post('outlet-staff',payload)
           .then(res => {
               return res.data;
           })
    }

    getStaffs(payload){
        let url = `outlet-staff-all?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{user_id:payload.user_id})
            .then(res => {
                return res.data;
            })
    }

    getRoles(){
        return axios.get('roles')
            .then(res => {
                return res.data;
            })
    }
}



export default new OutletStaffService();
