import payments from "@/services/Payments";
import orders from "@/services/Orders";

const state = {
    payment_requests:[],
    total_items:0
}

const getters = {
    getPaymentRequest: (state) => {
        return state.payment_requests;
    },
}

const mutations = {
    updatePaymentRequest: (state,payload) =>{
        state.payment_requests = payload;
        state.total_items = payload.total
    }
}

const actions = {
    updatePaymentRequest({commit},payload) {
        return payments.paymentrequest(payload).then(
            response => {
                commit('updatePaymentRequest',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    approveRequest({commit},payload) {
        return payments.approveRequest(payload).then(
            response => {
                //commit('updateUnvalidated',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

