import moment from 'moment'
export const advance_fields = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'amount',
        label: 'Amount Borrowed',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Date',
        sortable: true,
        sortByFormatted: true,
        formatter: (value, key, item) => {
            return moment(item.created_at).format('DD-MM-YYYY')
        },
    },
    {
        key: 'mode',
        sortable: true,
    },
    {
        key: 'account_number',
        sortable: true,
    },
    {
        key: 'status',
        sortable: true,
    }
];
