<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 xl-100 box-col-12">
          <div slot="with-padding" class="crypto-current">
          <h3>Activate your advance request and get instant loan with us</h3>
        </div>
        </div>
      </div>
      <div class="row">

        <div class="col-xl-4 xl-100 box-col-12" v-for="item in getloanPackages">
          <px-card class="current-balk">
            <div slot="with-padding" class="crypto-current dropdown-basic">
              <div class="row">
                <div class="col-xl-10 col-sm-10">
                  <div class="current-balance"><span class="f-12 f-w-600">{{item.name}}</span>
                    <h2><span class="mr-2"></span><span>KES {{item.amount}}</span></h2>

                   <div v-html="item.description"></div>
                   <a href="#">Learn More</a>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-pill btn-gradient-custom" @click="activatePackage(item.id)">Activate</button>
                  </div>
                </div>

              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters,} from "vuex";

export default {

  mounted() {
    this.$store.dispatch('updateLoanPackages');
  },
watch:{
  getloanPackages(){
    console.log(this.getloanPackages)
  }
},
  computed:{
    ...mapGetters(['getloanPackages'])
  },

  methods:{
    activatePackage(id){
      let user = JSON.parse(localStorage.getItem('user'));
      let payload = {};
      payload.user_id = user.user.id;
      payload.loan_package_id = id;

      this.$swal({
        text:'Are you Sure You want to activate this package?',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        confirmButtonColor: '#4466f2',
        cancelButtonText: 'No',
        cancelButtonColor: '#4466f2',
      }).then((result)=>{
        if(result.value){
          this.$store.dispatch('activatePackage',payload).then(() =>{
            this.$swal({
              title:"Great!",
              text:"Package successfully activated!",
              type:'success'
            });
          }).catch(error => {
            this.$swal({
              text:"Package Activation Failed."
            });
          })

        }else{
          this.$swal({
            text:"Package Activation Failed."
          });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
