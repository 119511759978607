<template>
<div>
  <!-- Container-fluid starts-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-4 xl-100 box-col-12">
        <px-card class="current-balk">
          <div slot="with-padding" class="crypto-current dropdown-basic">
            <div class="row">
              <div class="col-xl-10 col-sm-10">
                <div class="current-balance"><span class="f-12 f-w-600">Your Current Balance</span>
                  <h5><span class="mr-2"> </span><span>{{stats.balance | toCurrency}}</span></h5>
                  <template v-if="stats.balance >= 250">
                    <div class="dropdown btn-vertical-align d-flex">
                      <button class="btn dropbtn btn-pill btn-gradient-custom">Withdraw <span class="round-shape" style="margin-top: 4%"><i class="icofont icofont-arrow-right"></i></span></button>
                      <div class="setting-dot d-inline-block">
                        <div class="setting-bg"><i class="fa fa-spin fa-cog font-primary"></i></div>
                      </div>
                      <div class="dropdown-content">
                        <br>
                        <h5 class="text-center">Please choose mode of transfer</h5>
                        <a  id="show-btn-bank" @click="$bvModal.show('bv-modal-bank')"> Bank </a>
                        <a id="show-btn-mobile" @click="$bvModal.show('bv-modal-mobile')"> Mpesa </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <b-modal id="bv-modal-bank" centered hide-footer>
                <template #modal-title>
                  <span>Account Details</span>
                </template>
                <form @submit.prevent="withdrawToBank()">
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <b>Account Name</b><br>
                        <span style="margin-left: 4%" v-if="bank_account_details.account_name">{{bank_account_details.account_name}}</span>
                      </p>

                    </div>
                    <div class="col-md-6">
                      <p>
                        <b>Account Number</b><br>
                        <span style="margin-left: 4%" v-if="bank_account_details.account_number">{{bank_account_details.account_number}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <b>Phone Number</b><br>
                        <span style="margin-left: 4%" v-if="bank_account_details.phone_number">{{ bank_account_details.phone_number }} </span>
                      </p>
                    </div>
                  <div class="col-md-6">
                    <p>
                      <b>Amount</b><br>
                      <input type="number" step="0.01" min="200" class="form-control" v-model="form.amount" required>
                    </p>
                  </div>
                  </div>
<!--                  <hr>-->
<!--                  <div class="row">-->
<!--                    <p class="f-16 f-w-800 ml14">-->
<!--                      <b>Enter Otp sent to the number listed above</b>-->
<!--                    </p>-->


<!--                    <div style="display: flex; flex-direction: row;margin-left: 18%">-->
<!--                      <v-otp-input-->
<!--                          ref="otpInput"-->
<!--                          input-classes="otp-input"-->
<!--                          separator=""-->
<!--                          :num-inputs="4"-->
<!--                          :should-auto-focus="true"-->
<!--                          :is-input-num="true"-->
<!--                          @on-change="handleOnChange"-->
<!--                          @on-complete="handleOnComplete"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="form-group text-center">
                    <button class="btn btn-pill btn-gradient-custom mt-3 pr-10 pl-10" :disabled="!bank_account_details || amount < 200" style="padding-right: 10%;padding-left: 10%"> Withdraw </button>
                  </div>
                </form>
              </b-modal>

              <b-modal id="bv-modal-mobile" centered hide-footer>
                <template #modal-title>
                  <span>Account Details</span>
                </template>
                <form @submit.prevent="withdrawToMobile()">
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <b>Account Name</b><br>
                        <span style="margin-left: 4%" v-if="bank_account_details.account_name">{{bank_account_details.account_name}}</span>
                      </p>

                    </div>
                    <div class="col-md-6">
                      <p>
                        <b>Account Number</b><br>
                        <span style="margin-left: 4%" v-if="bank_account_details.account_number">{{bank_account_details.account_number}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <b>Phone Number</b><br>
                        <span style="margin-left: 4%" v-if="bank_account_details.phone_number">{{ bank_account_details.phone_number }} </span>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p>
                        <b>Amount</b><br>
                        <input type="number" step="0.01" min="200" class="form-control" v-model="form.amount" required>
                      </p>
                    </div>
                  </div>
                  <hr>
<!--                  <div class="row">-->
<!--                    <p class="f-16 f-w-800 ml14">-->
<!--                      <b>Enter Otp sent to the number listed above</b>-->
<!--                    </p>-->

<!--                    <div style="display: flex; flex-direction: row;margin-left: 18%">-->
<!--                      <v-otp-input-->
<!--                          ref="otpInput"-->
<!--                          input-classes="otp-input"-->
<!--                          separator=""-->
<!--                          :num-inputs="4"-->
<!--                          :should-auto-focus="true"-->
<!--                          :is-input-num="true"-->
<!--                          @on-change="handleOnChange"-->
<!--                          @on-complete="handleOnComplete"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->

                  <div class="form-group text-center">
                    <button class="btn btn-pill btn-gradient-custom mt-3 pr-10 pl-10" :disabled="!bank_account_details || form.amount < 200" style="padding-right: 10%;padding-left: 10%"> Withdraw </button>
                  </div>
                </form>
              </b-modal>

              <b-modal id="bv-modal-example" centered hide-footer>
                <template #modal-title>
                  <span>Account Details</span>
                </template>
                <form action="">
                  <div class="row">
                <div class="col-md-6">
                  <p>
                   <b>Account Name</b><br>
                    <span style="margin-left: 4%" v-if="bank_account_details.account_name">{{bank_account_details.account_name}}</span>
                  </p>

                </div>
                <div class="col-md-6">
                  <p>
                    <b>Account Number</b><br>
                    <span style="margin-left: 4%" v-if="bank_account_details.account_number">{{bank_account_details.account_number}}</span>
                  </p>
                </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <b>Phone Number</b><br>
                        <span style="margin-left: 4%" v-if="bank_account_details.phone_number">{{ bank_account_details.phone_number }} </span>
                      </p>
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                  <hr>
                  <div class="row">
                    <p class="f-16 f-w-800 ml14">
                     <b>Enter Otp sent to the number listed above</b>
                    </p>

                    <div style="display: flex; flex-direction: row;margin-left: 18%">
                      <v-otp-input
                          ref="otpInput"
                          input-classes="otp-input"
                          separator=""
                          :num-inputs="4"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          @on-change="handleOnChange"
                          @on-complete="handleOnComplete"
                      />
                    </div>
                  </div>
                  <div class="form-group text-center">
                    <button class="btn btn-pill btn-gradient-custom mt-3 pr-10 pl-10" style="padding-right: 10%;padding-left: 10%" @click="$bvModal.hide('bv-modal-example')">Withdraw </button>
                  </div>

                </form>
              </b-modal>

              <b-modal id="modal-3" centered title="Account Details" ok-title="Save Changes" class="theme-modal">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>Account Name</h5>

                      <span class="f-12 f-w-600">Moses Gathecha</span>
                      <h5>Phone Number</h5>
                      <span class="f-12 f-w-600">070234536</span>
                    </div>
                    <div class="col-md-6">
                      <h5>Account Number</h5>
                      <span class="f-12 f-w-600">1123243545</span>
                    </div>
                  </div>
                </form>
              </b-modal>

              <div class="col-xl-2 col-sm-2">
                <div class="text-right">
                  <div class="user-circle"><i class="icofont icofont-cur-dollar"></i></div>
                </div>
              </div>
            </div>
          </div>
        </px-card>
      </div>

      <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Invoices</h5>
                </div>
                <div class="col-md-6">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-4">
                  <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                  >
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="available_amount.data"
                    :fields="fields"
                    :per-page="perPage"
                >
               </b-table>
                <loader :active="loaderActive"  message="Please wait for few seconds" />
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="available_amount.data && available_amount.data.length >= 1"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="handlePageChange"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </px-card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from './../../components/Loader.vue';
import LoaderMixin from './../../mixins/loader';
import {getRequestParams} from '@/helpers/pagination'
import {amount_fields} from "@/pages/payments/fields/amount_field_defs";
export default {
  mixins:[LoaderMixin],
  components: {
    Loader
  },
  data() {
    return {
      fields: amount_fields ,
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      searchTitle: "",
      loading:false,
      form:{
        amount:1000
      }
    }
  },
mounted() {
  this.showLoader();
  this.retrieveAvailableAmount();
  this.$store.dispatch('updateAccount')
  this.$store.dispatch('updateStats')
  this.hideLoader();
},
computed:{
  ...mapGetters({available_amount:'getAvailableAmount',amount:'getAmount',bank_account_details:'getBankAccount',stats:'getStats'})
},
  watch: {
    available_amount() {
      this.totalRows = this.available_amount.total;
    },
    searchTitle() {
      this.retrieveAvailableAmount();
    },
    bank_account_details() {
      console.log(this.bank_account_details)
    }
  },
  methods:{
    withdrawToBank() {
     this.form.payment_mode='bank';
     this.form.account_number=this.bank_account_details.account_number;
     this.form.user_id = localStorage.getItem('user_id');
     this.form.merchant_id = localStorage.getItem('merchant_id');
    if (this.form.amount < 200) {
     return  this.error('amount cannot be less than 200 shillings.');
    }
     this.$store.dispatch('withdrawFunds',this.form).then(() =>{
       this.retrieveAvailableAmount();
       this.$root.$emit('bv::hide::modal', 'bv-modal-bank', '#show-btn-bank')
       this.success("Withdrawal was successfully initiated.Please wait for approval.")
     })
    },
    withdrawToMobile() {
      this.form.payment_mode=1; //mpesa
      this.form.account_number=this.bank_account_details.phone_number;
      this.form.user_id = localStorage.getItem('user_id');
      this.form.merchant_id = localStorage.getItem('merchant_id');
      if (this.form.amount < 200) {
        return  this.error('amount cannot be less than 200 shillings.');
      }
      this.$store.dispatch('withdrawFunds',this.form).then(() =>{
        this.retrieveAvailableAmount();
        this.$root.$emit('bv::hide::modal', 'bv-modal-mobile', '#show-btn-mobile');
        this.success("Withdrawal was successfully initiated.Please wait for approval.")
      })
    },
    retrieveAvailableAmount() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );

      this.$store.dispatch('updateAvailableAmount',params);
    },
    handlePageChange(value) {
      this.currentPage = value
      this.retrieveAvailableAmount();
    },
    handleOnComplete(value) {
      console.log('OTP completed: ', value);
    },
    handleOnChange(value) {
      console.log('OTP changed: ', value);
    },
    error(message)
    {
      this.$toasted.show(message, {theme: 'outline',position: "top-right", type: 'error', duration: 6000});
    },
    success(message)
    {
      this.$toasted.show(message, {theme: 'outline',position: "top-right", type: 'success', duration: 6000});
    },
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.ml4{
  margin-left: 4%;
}

</style>
