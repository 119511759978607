import roles from "@/services/Role";

const state = {
    roles:[],
    permissions:[]
}

const getters = {
    getAllRoles: (state) => {
        return state.roles;
    },
    getAllPermissions: (state) => {
        return state.permissions;
    }
}

const mutations = {
    updateRole: (state,payload) =>{
        state.roles = payload;
    },
    updatePermission: (state,payload) =>{
        state.permissions = payload;
    }
}

const actions = {
    updateRole({commit},payload) {
        return roles.getRoles(payload).then(
            response => {
                commit('updateRole',response);
               return Promise.resolve(response)
            },
            error => {
             return Promise.reject(error);
            }
        )
    },
    updatePermission({commit},payload) {
        return roles.getPermissions(payload).then(
            response => {
                commit('updatePermission',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    addPermission({commit},payload) {
        return roles.addPermissions(payload).then(
            response => {
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

