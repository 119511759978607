export const amount_fields = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'booking.booking_reference',
        label: 'Booking Reference',
        sortable: true
    },
    {
        key: 'date_created',
        label: 'Date',
        sortable: true,
    },
    {
        key: 'validated_price',
        label: 'Amount',
        sortable: true,
    }
];
