import outlets from "@/services/Outlets";

const state = {
    outlets:[]
}

const getters = {
    getOutlets: (state) => {
        return state.outlets;
    }
}

const mutations = {
    updateOutlet: (state,payload) =>{
        state.outlets = payload;
    }
}

const actions = {
    updateOutlet({commit},payload) {
        return outlets.getOutlets(payload).then(
            response => {
                commit('updateOutlet',response);
               return Promise.resolve(response)
            },
            error => {
             return Promise.reject(error);
            }
        )
    }
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

