<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Payment Requests</h5>
                </div>
                <div class="col-md-6">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-4">
                  <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                  >
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="payment_request.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                  <template #cell(actions)="row">
                    <b-dropdown variant="transparent" class="m-md-2" no-caret>
                      <template #button-content>
                        <feather type="more-vertical"></feather>
                      </template>
                      <b-dropdown-item @click="approveRequest(row.item, row.index, $event.target)">Approve</b-dropdown-item>
                      <b-dropdown-item @click="rejectRequest(row.item, row.index, $event.target)">Reject</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>


              </div>

              <b-modal :id="rejectModal.id"  :title="'Enter Reason'" hide-footer @hide="resetRejectModal" centered>
                <form @submit.prevent="handleReject">

                  <div class="form-group">
                    <input type="text" class="form-control" v-model="form1.reject_reason" required>
                  </div>

                  <div class="form-group text-center">
                    <button class="btn btn-pill btn-gradient-custom" type="submit">Reject</button>
                  </div>
                </form>
              </b-modal>

              <b-modal :id="approveModal.id" :title="'Are you Sure?'" hide-footer @hide="resetApproveModal" centered>
                <form @submit.prevent="handleApprove">

                  <div class="form-group text-center">
                    <button class="btn btn-pill btn-gradient-custom" type="submit" :disabled="!enable_approve">Approve</button>
                  </div>
                </form>
              </b-modal>


              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="payment_request.data && payment_request.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {payment_request_field_defs} from "@/pages/payments/fields/requests_field_defs";
import LoaderMixin from "@/mixins/loader";
import Loader from "@/components/Loader.vue";
export default {
  mixins:[LoaderMixin],
  components: {
    Loader
  },
  data() {
    return {
      fields: payment_request_field_defs,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      searchTitle: "",
      enable_approve: true,
      refund_receipt_no:'',
      validate_receipt_no:'',
      form1:{
        reject_reason:'',
      },
      rejectModal: {
        id: 'reject-modal',
        title: '',
        content: ''
      },
      approveModal: {
        id: 'approve-modal',
        title: '',
        content: ''
      },
    }
  },
  mounted() {
    this.retrievePaymentRequest();
  },

  computed:{
    ...mapGetters({payment_request:'getPaymentRequest'})
  },
  watch: {
    payment_request() {
      console.log(this.payment_request)
      this.totalRows = this.payment_request.total;
    },
    searchTitle() {
      this.retrievePaymentRequest();
    }
  },
  methods:{
    retrievePaymentRequest() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      this.$store.dispatch('updatePaymentRequest',params);
    },

    handlePageChange(value) {
      this.currentPage = value
      this.retrievePaymentRequest();
    },

    approveRequest(item, index, button) {
      this.form1.status = 'Approved';
      this.form1.user_id = localStorage.getItem('user_id');
      this.form1.payment_request_id = item.id;

      this.approveModal.title = `Row index: ${index}`
      this.approveModal.content = 'init validate'
      this.$root.$emit('bv::show::modal', this.approveModal.id, '.dropdown-item')

    },
    resetApproveModal() {
      this.approveModal.title = ''
      this.approveModal.content = ''
    },

    rejectRequest(item, index, button) {
      this.form1.status = 'Rejected';
      this.form1.user_id = localStorage.getItem('user_id');
      this.form1.payment_request_id = item.id;

      this.rejectModal.title = `Row index: ${index}`
      this.rejectModal.content = 'init refund'
      this.$root.$emit('bv::show::modal', this.rejectModal.id, button)

    },
    resetRejectModal() {
      this.rejectModal.title = ''
      this.rejectModal.content = ''
    },
    handleReject() {
      this.$root.$emit('bv::hide::modal', this.rejectModal.id, '.dropdown-item');
      this.submitRequest();
      this.success('Record has been Rejected');
      this.form1.receipt_no = '';
    },

    handleApprove() {
      this.enable_approve = false;
      this.$store.dispatch('approveRequest',this.form1).then(() => {
        this.$root.$emit('bv::hide::modal', this.approveModal.id, '.dropdown-item');
        this.submitRequest();
        this.success('Record has been validated');
        this.enable_approve = true;
      })
    },

    submitRequest() {
      axios.post('payment/approve-request',this.form1)
          .then((response) => {
            this.$router.go();
            return response.data;
          })
    }

  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}
</style>
