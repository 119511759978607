import OutletStaff from "@/services/OutletStaff";
import advance from "@/services/Advance";

const state = {
    staff:{},
    staffs:{},
    roles:{}
}

const mutations = {
    updateStaff(state,payload) {
        state.staff = payload;
    },
    updateStaffs(state,payload) {
        state.staffs = payload;
    },
    updateRoles(state,payload) {
        state.roles = payload;
    }
}

const getters = {
    getStaff(state) {
     return state.staff;
    },
    getStaffs(state) {
        return state.staffs;
    },
    getRoles(state) {
        return state.roles;
    }
}

const actions = {
    updateStaff({commit}, payload) {
       return OutletStaff.getStaff(payload).then(response => {
           commit('updateStaff',response)
           return Promise.resolve(response);
       },
        err => {
           return Promise.reject(err);
        }
       )
    },
    updateStaffs({commit}, payload) {
        return OutletStaff.getStaffs(payload).then(response => {
                commit('updateStaffs',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    addStaff({commit},payload) {
        return OutletStaff.addStaff(payload).then(response => {
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    updateRoles({commit}, payload) {
        return OutletStaff.getRoles(payload).then(response => {
                commit('updateRoles',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    }
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}
