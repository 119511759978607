<template>
<div>
  <section class="fxt-template-animation fxt-template-layout17 bg_image">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-6 col-lg-7 col-sm-12 col-12 fxt-bg-color">
          <div class="fxt-content">
            <div class="fxt-header">
              <a href="/" class="fxt-logo"><img src="../../assets/images/logo.png" alt="Logo" width="45%"></a>

              <div class="fxt-style-line">
                <h2>Login To Proceed</h2>
              </div>
            </div>

            <div v-if="message" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
                <p class="text-danger">
                  {{ message }}
                </p>
            </div>
            <div class="fxt-form">
              <form method="POST" @submit.prevent="handleLogin">
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-1">
                    <input type="email" class="form-control" v-model="user.email" placeholder="Email Address" required>
                  </div>
                </div>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-2">
                    <input type="password" class="form-control" v-model="user.password" placeholder="Password" required>
                  </div>
                </div>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-3">
                    <div class="fxt-checkbox-area">
                      <div class="checkbox">
                        <input id="checkbox1" type="checkbox">
                        <label for="checkbox1">Keep me logged in</label>
                      </div>
                      <router-link to="forgot-password" class="switcher-text">Forgot Password</router-link>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-4">
                    <button type="submit" class="fxt-btn-fill">Log in</button>
                  </div>
                </div>
              </form>
            </div>
            <div class="fxt-footer">
              <div class="fxt-transformY-50 fxt-transition-delay-5">
                <p>Don't have an account? <router-link to="register" class="switcher-text2 inline-text">Register</router-link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import User from '../../models/user';
export default {
  name: "login",
  data() {
    return {
      loading: false,
      message: '',
      user:{
        email: "",
        password: ""
      },
    };
  },
  mounted() {
   if (this.loggedIn)
     this.$router.replace({path:'/'})
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
    handleLogin(){
      this.loading = true;
        if (this.user.email && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
              () => {
                window.location.href = '/'
              },
              error => {
                console.log('error')
                console.log(error)
                this.message = error.response.data.error;
                this.loading = false;
              }
          );
        }
    }

  },
};
</script>

<style scoped>
@import '../../assets/css/bootstrap.min.css';
@import '../../assets/css/style.css';
@import '../../assets/css/fontawesome-all.min.css';
.bg_image {
  background-image: url('~@/assets/images/bg17-l.jpg');
}

</style>
