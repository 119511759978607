import axios from "axios";

const merchant_id = localStorage.getItem('merchant_id');
class MerchantService {
    bankAccount(){
        return axios.get(`bank-account/${merchant_id}`)
            .then((response) => {
                return response.data;
            })
    }
    withdraw(payload){
        return axios.post('payment-request',payload)
            .then((response) => {
                return response.data;
            })
    }

    validate(payload){
        return axios.post('order/validate',payload)
            .then((response) => {
                return response.data;
            })
    }

    getStats(payload){
        return axios.post('get-stats', {merchant_id})
            .then((response) => {
                console.log(response.data)
                return response.data;
            })
    }



}
export default new MerchantService();

