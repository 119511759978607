import axios from "axios";

class OutletsService {

    getOutlets(payload){
       return axios.get(`outlets/${payload}`)
           .then(res => {
               return res.data;
           })
    }
}



export default new OutletsService();
