<template>
  <div>
    <div class="contaner-fluid">
      <div class="row">
        <div class="col-xl-4" v-for="goal in goals.data" :key="goal.id">
          <px-card>
            <div slot="with-padding">
              <div class="timeline-small">
                <div class="media">
                  <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="shopping-bag"></feather></div>
                  <div class="media-body">
                    <h6>{{goal.product.product_name}} <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')" style="cursor: pointer">Send Offer</span><p class="f-14 amount">Ksh {{goal.booking_price}}</p></h6>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
      <b-modal id="bv-modal-example" centered hide-footer>
       <template #modal-title>
       <span>Customer Goals</span>
        </template>
        <form action="">
          <div class="row">
            <p>
              <b>Name</b><br>
              <input type="text" class="form-control" v-model="form.name" required>
            </p>
            <p>
              <b>Description</b><br>
              <textarea v-model="form.description" cols="3" rows="1" class="form-control" required></textarea>
            </p>
          </div>
          <p>
            <b>Amount Bid</b><br>
            <input type="text" v-model="form.amount" class="form-control" required>
          </p>
          <p>
            <b>Offers</b><br>
            <textarea v-model="form.offers" cols="5" rows="2" required class="form-control"></textarea>
          </p>

          <div class="form-group text-center">
            <button class="btn btn-pill btn-gradient-custom mt-3 pr-10 pl-10" style="padding-right: 10%;padding-left: 10%" @click="$bvModal.hide('bv-modal-example')">Send Bid </button>
          </div>
        </form>
      </b-modal>
      <div class="row">
        <div class="overflow-auto" style="margin-bottom: 1%">

          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="handlePageChange"
              aria-controls="my-table"
              prev-text="Previous"
              next-text="Next"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGallery from 'vue-gallery';
import {validated_field_defs} from "@/pages/orders/fields/validated_field_defs";
import {getRequestParams} from "@/helpers/pagination";
import {mapGetters} from "vuex";
export default {
  data(){
    return{
      fields: validated_field_defs,
      totalRows: 1,
      currentPage2: 2,
      currentPage: 1,
      perPage: 20,
      perPage2: 3,
      pageOptions: [20, 40, 80],
      searchTitle: "",
      gindex:null,
      items: [],
      form:{
        name:'',
        description:'',
        amount:'',
        offers:''
      },
      lightgallery:[
        {
          image:'01.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        },
        {
          image:'02.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        },
        {
          image:'03.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        },
        {
          image:'04.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        },
        {
          image:'05.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        },
        {
          image:'06.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        },
        {
          image:'07.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        },
        {
          image:'08.jpg',
          title:'Portfolio Title',
          description:"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
        }
      ],
      biglightgallery:[
        '01.jpg',
        '02.jpg',
        '03.jpg',
        '04.jpg',
        '05.jpg',
        '06.jpg',
        '07.jpg',
        '08.jpg'
      ],
      galleryoptions:{
        prevClass: 'prev',
        nextClass: 'next',
      }
    }
  },
  components: {
    VueGallery
  },
  watch:{
    goals() {
      console.log('walll')
      console.log(this.goals)
      this.totalRows = this.goals.total;
      this.items = this.goals.data;

      console.log('-----------------')
      console.log(this.items)
      console.log('===================')
    }
  },
  mounted(){
    this.retrieveGoals();
    this.setimagesparth();
  },
  computed:{
    ...mapGetters({goals:'getGoals'}),
    rows2() {
      return this.items.length
    }

  },
  methods:{
    retrieveGoals() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
     this.$store.dispatch('updateGoal',params)
    },
    handlePageChange(value) {
      console.log('cur val '+value)
      this.currentPage = value
      this.retrieveGoals();
    },
    setimagesparth(){
      for (var i = 0; i < this.biglightgallery.length; i++) {
        this.biglightgallery[i] = this.getbigimgpath(this.biglightgallery[i]);
      }
    },
    getpath(filename){
      return require('@/assets/images/lightgallry/'+filename)
    },
    getbigimgpath(filename){
      return require('@/assets/images/big-lightgallry/'+filename)
    }
  }
}
</script>
