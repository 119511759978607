import merchants from "@/services/Merchants";

const state = {
    bank_account:0,
    stats:{},
}

const getters = {
    getBankAccount: (state) => {
        return state.bank_account;
    },
    getStats: (state) => {
        return state.stats;
    },
}

const mutations = {
    updateAccount: (state,payload) =>{
        state.bank_account = payload;
    },
    updateStats: (state,payload) =>{
        state.stats = payload;
    }
}

const actions = {
    updateAccount({commit}) {
        return merchants.bankAccount().then(
            response => {
                commit('updateAccount',response);
               return Promise.resolve(response)
            },
            error => {
             return Promise.reject(error);
            }
        )
    },
    withdrawFunds({commit},payload) {
        return merchants.withdraw(payload).then(
            response => {
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    validateOrder({commit},payload) {
        return merchants.validate(payload).then(
            response => {
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    },

    updateStats({commit},payload) {
        return merchants.getStats(payload).then(
            response => {
                commit('updateStats',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

