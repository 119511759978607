import axios from 'axios';

class AdvanceService {
    getLoanPackages() {
       return  axios.get('loan/package')
            .then(res => {
                return res.data;
            })
    }

    activatePackage(payload) {
        return  axios.post('loan/package/activate',payload)
            .then(res => {
                return res.data;
            })
    }

    getActivatedPackage(payload) {
        return  axios.get(`loan/package/active/${payload}`)
            .then(res => {
                return res.data;
            })
    }

    addAdvance(payload) {
        return  axios.post('advance',payload)
            .then(res => {
                return res.data;
            })
    }
    getAdvances(payload){
        let url = `advance/all?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{user_id:payload.user_id})
            .then((response) => {
                return response.data;
            })
    }
}


export default new AdvanceService();

