import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
// Bootstrap Framework Component
import {BootstrapVue} from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import SmartTable from 'vuejs-smart-table'
import Toasted from 'vue-toasted';
// Vue Router
import router from './router'
import moment from 'moment';


// Vue Router
import Breadcrumbs from './components/bread_crumbs'

// Vuex Store
import { store } from './store';
import VueFeather from 'vue-feather';

import PxCard  from './components/Pxcard.vue'
Vue.component(PxCard.name, PxCard)
Vue.use(Toasted,{
  iconPack: 'fontawesome'
});
import VueApexCharts from 'vue-apexcharts'
import Chartist from "chartist";

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);
// Import Theme scss
import './assets/scss/app.scss'
Vue.use(require('vue-moment'));

//axios.defaults.baseURL="http://localhost:8000/api/";
axios.defaults.baseURL="https://merchants.flexpay.co.ke/backend/api/";
Vue.config.productionTip = false

Vue.use(VueFeather);
Vue.use(BootstrapVue);
//Vue.use(BootstrapVuePlugin);
Vue.use(VueSweetalert2);
Vue.use(SmartTable);
Vue.use(require('vue-chartist'))
Vue.component('apexchart', VueApexCharts)
Vue.component('Breadcrumbs', Breadcrumbs)
//loader
Vue.component('pulse-loader', require('vue-spinner/src/PulseLoader.vue'));
Vue.prototype.$Chartist = Chartist;


Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'KES'
  });
  return formatter.format(value);
});






new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
