import goals from "@/services/CustomerGoalService";

const state = {
    goals:[],
    top_five_goals:[],
}

const getters = {
    getGoals: (state) => {
        return state.goals;
    },
    getTopGoals: (state) => {
        return state.top_five_goals;
    },
}

const mutations = {
    updateGoal: (state,payload) =>{
        state.goals = payload;
    },
    updateTopGoal: (state,payload) =>{
        state.top_five_goals = payload;
    },
}

const actions = {
    updateGoal({commit},payload) {
        return goals.getCustomerGoals(payload).then(
            response => {
                commit('updateGoal',response);
               return Promise.resolve(response)
            },
            error => {
             return Promise.reject(error);
            }
        )
    },
    updateTopGoal({commit},payload) {
        return goals.getTopCustomerGoals(payload).then(
            response => {
                commit('updateTopGoal',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}

export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

