import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto'
import layout from './modules/layout'
import menu from './modules/menu'
import {auth} from './modules/auth'
import payment from './modules/payment'
import order from './modules/order'
import advance from './modules/advance'
import outletstaff from './modules/outlet_staff'
import roles from './modules/roles'
import outlets from './modules/outlets'
import goals from './modules/goals'
import merchants from './modules/merchant'
import payment_request from './modules/payment_request'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    modules: {
        layout,
        menu,
        auth,
        payment,
        order,
        advance,
        outletstaff,
        roles,
        outlets,
        goals,
        merchants,
        payment_request
    },
   // plugins: [createPersistedState()],
    strict: false
});

