import axios from "axios";
const merchant_id = localStorage.getItem('merchant_id');
class CustomerGoalService {

    getCustomerGoals(payload){
        let url = `all-customer-goals/merchant/${merchant_id}?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
       return axios.get(url)
           .then(res => {
               return res.data;
           })
    }

    getTopCustomerGoals(payload){
        const merchant = 120;
        let url = `top-customer-goals/merchant/${merchant_id}`;
        return axios.get(url)
            .then(res => {
                return res.data;
            })
    }
}



export default new CustomerGoalService();
