<script>
    import { Line } from 'vue-chartjs'
    //Datasets array
    // 'label'=>$key,
    //     'backgroundColor' => $value['color'],
    //     'borderColor' => $value['color'],
    //     'data' => $value['data'],
    export default {
        extends: Line,
        data() {
            return {
                linechart: {
                    labels: ["January","February", "March", "April", "May", "June", "July", "August", "September","October","November","December"],
                    datasets: [{
                       label:'Validated',
                        backgroundColor: "rgba(68, 102, 242, 0.2)",
                        borderColor: "#544fff",
                        pointBackgroundColor: "#544fff",
                        data: [10, 20, 40, 30, 0, 20, 10, 30, 10,12,16,20],
                    },{
                       label:'UnValidated',
                        backgroundColor: "rgba(30, 166, 236, 0.2)",
                        borderColor: "#158df7",
                        pointBackgroundColor: "#158df7",
                        data: [20, 40, 10, 20, 40, 30, 40, 10, 20,14,18,70]
                    }, {
                       label:'Refunded',
                        backgroundColor: "rgb(64, 153, 255 , 0.2)",
                        borderColor: "#fb2e63",
                        pointBackgroundColor: "#fb2e63",
                        data: [60, 10, 40, 30, 80, 30, 20, 90, 0,3,7,50]
                    }]
                },
                options: {
                    animation: {
                        duration: 1000,
                        easing: 'linear'
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        yAxes: [{ticks: { beginAtZero: true, stepSize: 5,}}],
                        xAxes: [{barThickness: 39,}],
                    },
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                    },
                    elements: {
                        line: {
                            tension: 0.000001
                        }
                    },

                }
            }
        },
        mounted () {
            this.renderChart(this.linechart, this.options)
        }
    }
</script>

<style>
</style>
