import axios from "axios";
const merchant_id = localStorage.getItem('merchant_id');
class PaymentService {
    availableAmount(payload){
        let url = `payment/available-amount?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{merchant_id})
            .then((response) => {
                console.log('post rq')
                return response.data;
            })
    }

    payout(payload){
        let url = `payment/pay-out?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{merchant_id})
            .then((response) => {
                return response.data;
            })
    }

    paymentrequest(payload){
        let url = `payment/requests?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url)
            .then((response) => {
                return response.data;
            })
    }

    approveRequest(payload){
        return axios.post('payment/approve-request',payload)
            .then((response) => {
                return response.data;
            })
    }

}

export default new PaymentService();

