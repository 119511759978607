<template>
  <div>
    <section class="fxt-template-animation fxt-template-layout17 bg_image">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-7 col-sm-12 col-12 fxt-bg-color">
            <div class="fxt-content">
              <div class="fxt-header">
                <a href="/" class="fxt-logo"><img src="../../assets/images/logo.png" alt="Logo" width="45%"></a>

                <div class="fxt-style-line">
                  <h2>Reset Account</h2>
                </div>
              </div>
              <div v-if="errors" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
<!--                <div v-for="(v, k) in errors" :key="k">-->
                  <p v-for="error in errors" :key="error" class="text-sm text-danger">
                    {{ error }}
                  </p>
<!--                </div>-->
              </div>
              <div class="fxt-form">
                <form  @submit.prevent="handleReset">
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-1">
                      <input type="email" class="form-control" v-model="form.email" placeholder="Email Address" readonly>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-2">
                      <input type="password" class="form-control" v-model="form.password" placeholder="Password" required autocomplete="off">
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-2">
                      <input type="password" class="form-control" v-model="form.password_confirmation" placeholder="Confirm Password" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-4">
                      <button type="submit" class="fxt-btn-fill">Reset</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "login",
  data() {
    return {
      submitted: false,
      successful: false,
      message:'',
      errors:'',
      form: {
        email:'',
        password:'',
        password_confirmation:'',
        token:''
      }
    };
  },
  created() {
    let searchParams = new URLSearchParams(window.location.search)
    let token='empty';
   if (searchParams.has('token')){
     token = searchParams.get('token');
   }
   this.form.token = token;
   this.form.email = localStorage.getItem('login_email')
    console.log(this.form.email)
  },
  computed:{
    ...mapState({
      user: state => state.auth.current_user
    })
  },
  methods: {
    handleReset() {
      this.message = '';
      this.submitted = true;

      this.$store.dispatch('auth/reset', this.form).then(
          () => {
            this.$swal({
              title:"Good job!",
              text:"Password successfully reset!",
              type:'info'
            }).then(() => {
              this.$router.replace({path: '/auth/login'})
            })
          },
          error => {
           this.errors = error.response.data
           this.successful = false;
          }
      );
    }
  },
};
</script>

<style scoped>
@import '../../assets/css/bootstrap.min.css';
@import '../../assets/css/style.css';
@import '../../assets/css/fontawesome-all.min.css';
.bg_image {
  background-image: url('~@/assets/images/bg17-l.jpg');
}
</style>
