export const user_fields = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'first_name',
        sortable: true
    },
    {
        key: 'last_name',
        sortable: true,
    },
    {
        key: 'email',
        sortable: true,
    },
    {
        key: 'phone_number',
        sortable: true,
    }
];
