<template>
  <div>
    <h3>Permissions</h3>
    <!-- Container-fluid starts-->
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <form @submit.p.prevent="onSubmit">
          <div class="row">
            <div class="col-md-6">
            <h4>Administrator Permissions</h4>
             <ul class="list-group">
              <li class="list-group-item" v-for="a in permissions" :key="a.id">
                <div class="checkbox">
                <b-form-checkbox class="checkbox-solid-primary"  :value="a.id" v-model="selectedAdminPermission">{{a.name}}</b-form-checkbox>
                </div>
              </li>
              </ul>
            </div>
            <div class="col-md-6">
              <h4>Store Manager Permissions</h4>
              <ul class="list-group">
                <li class="list-group-item" v-for="s in permissions" :key="s.id">
                  <div class="checkbox">
                <b-form-checkbox class="checkbox-solid-primary"  :value="s.id" v-model="selectedStorePermission">{{s.name}}</b-form-checkbox>
                  </div>
                </li>
              </ul>
            </div>
          <div class="form-group mt-4">
            <button class="btn btn-primary" type="submit">Update</button>
          </div>

          </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
    payload:{},
      selectedAdminPermission:[],
      selectedStorePermission:[],
    }
  },
watch: {
  roles() {
    this.roles.forEach(p => {
      if (p.id == 1 && p.permission) {
        this.selectedAdminPermission = JSON.parse(p.permission);
      }

      if (p.id == 2 && p.permission) {
        this.selectedStorePermission = JSON.parse(p.permission);
      }
    })
  }
},
  mounted() {
    this.$store.dispatch('updateRole');
    this.$store.dispatch('updatePermission');
  },
  computed:{
    ...mapGetters({roles:'getAllRoles',permissions:'getAllPermissions'})
  },
  methods:{
    onSubmit(){
      this.$store.dispatch('addPermission',{admin:this.selectedAdminPermission,store:this.selectedStorePermission})
          .then(() =>this.success('Permissions successfully updated.'))
    },
    success(message)
    {
      this.$toasted.show(message, {theme: 'outline',position: "top-right", fullWidth:true, type: 'success', duration: 2000});
    },
  }
}
</script>

<style scoped>
.checkbox-solid-primary {

}

</style>
