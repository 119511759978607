<template>
  <div>
    <section class="fxt-template-animation fxt-template-layout17 bg_image">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-7 col-sm-12 col-12 fxt-bg-color">
            <div class="fxt-content">
              <div class="fxt-header">
                <a href="/" class="fxt-logo"><img src="../../assets/images/logo.png" alt="Logo" width="45%"></a>

                <div class="fxt-style-line">
                  <h2>Create Account</h2>
                </div>
              </div>

              <div v-if="errors" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm text-danger">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="fxt-form">
                <form  @submit.prevent="handleRegister">
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-1">
                      <input type="text" class="form-control" v-model="form.first_name" placeholder="First Name" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-1">
                      <input type="text" class="form-control" v-model="form.last_name" placeholder="Last Name" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-1">
                      <input type="text" class="form-control" v-model="form.phone" placeholder="Phone Number" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-1">
                      <input type="email" class="form-control" v-model="form.email" placeholder="Email Address" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-2">
                      <input type="password" class="form-control" v-model="form.password" placeholder="Password" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-2">
                      <input type="password" class="form-control" v-model="form.password_confirmation" placeholder="Confirm Password" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-3">
                      <div class="fxt-checkbox-area">
                        <div class="checkbox">
                          <input id="checkbox1" type="checkbox">
                          <label for="checkbox1">Keep me logged in</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-4">
                      <button type="submit" class="fxt-btn-fill">Register</button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="fxt-footer">
                <div class="fxt-transformY-50 fxt-transition-delay-5">
                  <p>Have you an account? <router-link to="login" class="switcher-text2 inline-text">Login</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      submitted: false,
      successful: false,
      message:'',
      errors:'',
      form: {
        first_name:'',
        last_name:'',
        email:'',
        phone:'',
        password:'',
        password_confirmation:'',
      }
    };
  },
  methods: {
    handleRegister() {
      this.message = '';
      this.submitted = true;
      this.$store.dispatch('auth/register', this.form).then(
          () => {
            this.$swal({
              title:"Good job!",
              text:"Account successfully registered!",
              type:'info'
            }).then(() => {
              console.log('callback')
              this.$router.replace({path: '/auth/login'})
            })
          },
          error => {
           this.errors = error.response.data
           this.successful = false;
          }
      );
    }
  },
};
</script>

<style scoped>
@import '../../assets/css/bootstrap.min.css';
@import '../../assets/css/style.css';
@import '../../assets/css/fontawesome-all.min.css';
.bg_image {
  background-image: url('~@/assets/images/bg17-l.jpg');
}
</style>
