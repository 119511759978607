import payments from "@/services/Payments";

const state = {
    available_amount:[],
    payout:[],
    total_items:0,
    amount:0,
    total_paid:0,
}

const getters = {
    getAvailableAmount: (state) => {
        return state.available_amount;
    },
    getPayout: (state) => {
        return state.payout;
    },
    getAmount: (state) => {
        return state.amount;
    },
    getPaidAmount: (state) => {
        return state.total_paid;
    },
}

const mutations = {
    updateAvailableAmount: (state,payload) =>{
        state.available_amount = payload;
        state.total_items = payload.total
    },
    updatePayout: (state,payload) =>{
        state.payout = payload;
        state.total_items = payload.total
    },
    updateAmount: (state,payload) =>{
        state.amount = payload
    },
    updateTotalPayout: (state,payload) =>{
        state.total_paid = payload
    }
}

const actions = {
    updateAvailableAmount({commit},payload) {
        return payments.availableAmount(payload).then(
            response => {
                console.log('----------')
                console.log(response.bookings)
                console.log('*****')
                commit('updateAvailableAmount',response.bookings);
                commit('updateAmount',response.amount);
               return Promise.resolve(response)
            },
            error => {
             return Promise.reject(error);
            }
        )
    },
    updatePayout({commit},payload) {
        return payments.payout(payload).then(
            response => {
                commit('updatePayout',response.payouts);
                commit('updateTotalPayout',response.total_paid);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

