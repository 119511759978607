<template>
  <div>
    <section class="fxt-template-animation fxt-template-layout17 bg_image">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-6 col-lg-7 col-sm-12 col-12 fxt-bg-color">
            <div class="fxt-content">
              <div class="fxt-header">
                <a href="/" class="fxt-logo"><img src="../../assets/images/logo.png" alt="Logo" width="45%"></a>
              </div>
              <div v-if="errors" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm text-danger">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="fxt-form">
                <form @submit.prevent="sendEmail">
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-1">
                      <input type="email" class="form-control" v-model="email" placeholder="Email Address" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="fxt-transformY-50 fxt-transition-delay-4">
                      <button type="submit" class="fxt-btn-fill" :disabled="!show_email">
                       <span v-if="show_email">
                         Submit
                        </span>
                        <span v-else>
                          Please Wait...
                        </span>

                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="fxt-footer">
                <div class="fxt-transformY-50 fxt-transition-delay-5">
                  <p>Don't have an account? <router-link to="register">Register</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "login",
  data() {
    return {
      email: "",
      errors:"",
      show_email:true
    };
  },
  created() {
    console.log('forgot password')
  },
  methods: {
    sendEmail() {
    this.errors = "";
   this.show_email = false;
   console.log( this.show_email)
      localStorage.setItem('login_email',this.email)
    this.$store.dispatch('auth/sendEmail',{email:this.email}).then(
        (res) => {
          this.$swal({
            title:"Good job!",
            text:"Password Reset Link has been sent to your email.",
            type:'info'
          }).then(() => {

          })
        },
        error => {
          this.errors = error.response.data;
          this.show_email = true;
        }
    )

    }
  }
};
</script>

<style scoped>
@import '../../assets/css/bootstrap.min.css';
@import '../../assets/css/style.css';
@import '../../assets/css/fontawesome-all.min.css';
.bg_image {
  background-image: url('~@/assets/images/bg17-l.jpg');
}
</style>
