export const payout_field_defs = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'amount',
        sortable: true,
    },
    {
        key: 'mode',
        sortable: true,
    },
    {
        key: 'account_number',
        sortable: true,
    },
    {
        key: 'phone_number',
        sortable: true,
    },
    {
        key: 'date_created',
        label: 'Date',
        sortable: true,
    },
];
