export const refund_field_defs = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'booking_reference',
        label: 'Booking Ref',
        sortable: true
    },
    {
        key: 'product_name',
        sortable: true,
    },
    {
        key: 'phone',
        sortable: true,
    },
    {
        key: 'name',
        sortable: true,
    },
    {
        key: 'date_created',
        label: 'Date',
        sortable: true,
    },
    {
        key: 'refund_amount',
        label: 'Amount',
        sortable: true,
    }
];
