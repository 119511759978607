import AuthService from "@/services/AuthService";

function getLocalStorageItem(){
  return JSON.parse(localStorage.getItem('user'));
}
export function auth_user() {
    if (!getLocalStorageItem().user)
        return AuthService.logout();

    return getLocalStorageItem().user;
}

export function auth_user_merchant_id() {
    if (!getLocalStorageItem().merchant_id)
        return AuthService.logout();

   return getLocalStorageItem().merchant_id;
}
