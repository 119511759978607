export const validated_field_defs = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'booking.booking_reference',
        label: 'Booking Ref',
        sortable: true
    },
    {
        key: 'product.product_name',
        label: 'Product',
        sortable: true,
    },
    {
        key: 'customer.phone_number_1',
        label: 'Phone',
        sortable: true,
    },
    {
        key: 'customer.first_name',
        label: 'Name',
        sortable: true,
    },
    {
        key: 'date_created',
        label: 'Date',
        sortable: true,
    },
    {
        key: 'validated_price',
        label: 'Net Amount',  
        sortable: true,
    }
];
